import { FacebookFilled, InstagramFilled, LinkedinFilled, MailFilled, CopyrightOutlined, YoutubeFilled, WhatsAppOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import React from 'react';
import { Link } from "react-router-dom";
import { SUPPORT } from '../../data/enums';
import { ReactComponent as IconDiscord } from '../../assets/sprites/icons/discord.svg';
import { ReactComponent as IconPsn } from '../../assets/sprites/icons/playstation.svg';
import { useMediaQuery } from 'react-responsive'
import Icon from '@ant-design/icons';

export default function FooterContent() {
    const { t } = useTranslation();

    const isMobile = useMediaQuery({ maxWidth: 600 });

    return (
        isMobile ? <>
            <div>
                <div className='mb-4'>
                    <p className='font-bold text-lg mb-2'>{t("about")}</p>
                    <p>{t("about_description")}</p>
                </div>
                <div id="contact" className='mb-4'>
                    <p className='font-bold text-lg mb-2'>{t("contact")}</p>
                    <div className='flex mb-2 justify-center'>
                        <MailFilled className='items-center flex mr-1' />
                        <a href={"mailto:" + t("vvoids_email")}>{t("vvoids_email")}</a>
                    </div>
                    <div className='flex justify-center'>
                        <WhatsAppOutlined className='items-center flex mr-1' />
                        <a href={"tel:" + t("vvoids_phone")}>{t("vvoids_phone")}</a>
                    </div>
                </div>
                <div className='mb-4'>
                    <div>
                        <p className='font-bold text-lg mb-2'>{t("follow")}</p>
                    </div>
                    <div className='flex justify-center flex-wrap gap-2'>
                        <a target='_blank' rel="noreferrer" href={SUPPORT.VVOIDS_FACEBOOK}><FacebookFilled className='items-center flex h-full w-8 text-3xl mr-2' /></a>
                        <a target='_blank' rel="noreferrer" href={SUPPORT.VVOIDS_INSTAGRAM}><InstagramFilled className='items-center flex h-full w-8 text-3xl mr-2' /></a>
                        <a target='_blank' rel="noreferrer" href={SUPPORT.VVOIDS_LINKEDIN}><LinkedinFilled className='items-center flex h-full w-8 text-3xl mr-2' /></a>
                        <a target='_blank' rel="noreferrer" href={SUPPORT.VVOIDS_DISCORD}><Icon className='items-center flex h-full w-8 text-3xl mr-2 ant_hover' component={IconDiscord} /></a>
                        <a target='_blank' rel="noreferrer" href={SUPPORT.VVOIDS_PSN}><Icon className='items-center flex h-full w-8 text-3xl mr-2 ant_hover' component={IconPsn} /></a>
                        <a target='_blank' rel="noreferrer" href={SUPPORT.VVOIDS_YOUTUBE}><YoutubeFilled className='items-center flex h-full w-8 text-3xl mr-2' /></a>
                    </div>
                </div>
            </div>
            <hr className='mt-1' />
            <div className='mt-4'>
                <Link className='underline block p-3' to={"/privacy"}>{t("privacy")}</Link>
                <Link className='underline block' to={"/terms"}>{t("terms")}</Link>
                <div className='flex justify-center p-3'>
                    <CopyrightOutlined className='items-center flex mr-1' />
                    <p>{t("copyright")}</p>
                </div>
            </div>
        </> :
            <>
                <div className='flex justify-around text-left'>
                    <div className='w-1/3 mb-2'>
                        <p className='font-bold text-lg mb-2'>{t("about")}</p>
                        <p>{t("about_description")}</p>
                    </div>
                    <div id="contact" className='w-1/3 min-w-min ml-4 mr-4 mb-2 pl-4 pr-4 border-l-2 border-r-2 border-solid'>
                        <p className='font-bold text-lg mb-2'>{t("contact")}</p>
                        <div className='flex mb-2'>
                            <MailFilled className='items-center flex mr-1' />
                            <a href={"mailto:" + t("vvoids_email")}>{t("vvoids_email")}</a>
                        </div>
                        <div className='flex'>
                            <WhatsAppOutlined className='items-center flex mr-1' />
                            <a href={"tel:" + t("vvoids_phone")}>{t("vvoids_phone")}</a>
                        </div>
                    </div>
                    <div className='mb-2 w-1/3'>
                        <div>
                            <p className='font-bold text-lg mb-2'>{t("follow")}</p>
                        </div>
                        <div className='flex flex-wrap gap-2'>
                            <a target='_blank' rel="noreferrer" href={SUPPORT.VVOIDS_FACEBOOK}><FacebookFilled className='items-center flex h-full w-8 text-3xl mr-2' /></a>
                            <a target='_blank' rel="noreferrer" href={SUPPORT.VVOIDS_INSTAGRAM}><InstagramFilled className='items-center flex h-full w-8 text-3xl mr-2' /></a>
                            <a target='_blank' rel="noreferrer" href={SUPPORT.VVOIDS_LINKEDIN}><LinkedinFilled className='items-center flex h-full w-8 text-3xl mr-2' /></a>
                            <a target='_blank' rel="noreferrer" href={SUPPORT.VVOIDS_DISCORD}><Icon className='items-center flex h-full w-8 text-3xl mr-2 ant_hover' component={IconDiscord} /></a>
                            <a target='_blank' rel="noreferrer" href={SUPPORT.VVOIDS_PSN}><Icon className='items-center flex h-full w-8 text-3xl mr-2 ant_hover' component={IconPsn} /></a>
                            <a target='_blank' rel="noreferrer" href={SUPPORT.VVOIDS_YOUTUBE}><YoutubeFilled className='items-center flex h-full w-8 text-3xl mr-2' /></a>
                        </div>
                    </div>
                </div>
                <hr className='mt-1' />
                <div className='flex justify-around mt-4'>
                    <Link className='underline' to={"/privacy"}>{t("privacy")}</Link>
                    <div className='flex'>
                        <CopyrightOutlined className='items-center flex mr-1' />
                        <p>{t("copyright")}</p>
                    </div>
                    <Link className='underline' to={"/terms"}>{t("terms")}</Link>
                </div>
            </>
    )
}