export const STATE = {
    DEBUG: "DEBUG",
    INFO: "INFO",
    WARNING: "WARNING",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
    UPLOADING: "UPLOADING",
    DONE: "DONE",
    INVALID: "INVALID",
    DISCARD: "DISCARD",
    VALID: "VALID",
    NONE: "NONE"
}

export const SUPPORT = {
    VVOIDS_FACEBOOK: "https://www.facebook.com/Zmadnesss",
    VVOIDS_INSTAGRAM: "https://www.instagram.com/zmadnesss/",
    VVOIDS_LINKEDIN: "https://www.linkedin.com/in/vdg-z/",
    VVOIDS_DISCORD: "https://discord.gg/EqGRf5M",
    VVOIDS_PSN: "https://psnprofiles.com/LiKeAbAwS_Vince?order=percent",
    VVOIDS_YOUTUBE: "https://www.youtube.com/channel/UCysUIX7btobPLX1GTttmEXA"
}

export const ENABLE_LOG = {
    AXIOS: true,
    NAVIGATE: true
}

export const DEFAULT = {
    GUID: "00000000-0000-0000-0000-000000000000",
    NUMBER: 0,
    TEXT: "",
    KB: 1024,
    STRINGEMPTY: ""
}
// export const Defaults = {
//     GuidEmpty: "00000000-0000-0000-0000-000000000000",
//     DateFormat: "YYYY-MM-DD",
//     DateFormatDTB: "YYYY-MM-DDTHH:mm:ss",
//     DateFormatFull: "DD MMMM YYYY",
//     TimeFormat: "HH:mm",
//     StringEmpty: "",
//     UrlPrefix: "https://",
//     Space: " "
// }

export const LANGUAGES = {
    NL: "nl",
    EN: "en",
    DE: "de",
    FR: "fr"
}

export const REGEX = {
    PASSWORD: "^(?=.*\\d)(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$",
    ALPHANUMERIC: "^[a-zA-Z0-9]+$",
    DIGITS: "^\\+\\d+$",
    NUMBER_SPACED: "\\B(?=(\\d{3})+(?!\\d))"
}

export const ROUTES = {
    HOME: "/",
    SANCTA: "sanctamaria"
}

export const QueryId = {
    SelectPhotobooth: "v1/select/sanctamaria/photobooth",
    UpdatePhotoboothPhoto: "v1/update/sanctamaria/photobooth/url"
}

export const ToastId = {
    ActionSuccess: "toast_mutate_success",
    ActionSuccessUpdate: "toast_mutate_success_update",
    ActionSuccessCreate: "toast_mutate_success_create",
    ActionSuccessDelete: "toast_mutate_success_delete",
    ActionSuccessSubmit: "toast_mutate_success_submit",
    ActionError: "toast_mutate_error",
    ActionWarning: "toast_mutate_warning",
    RequiredFields: "toast_required_fields",
    RequiredHttps: "toast_required_https"
}

export const QueryCase = {
    Unknown: 0,

    //Defaults
    Success: 200,
    Error: 400,

    //Customs
    Empty: 260,
    Invalid: 261,
    Expired: 262,
    Unverified: 263,
    Mismatch: 264,
    MissingParameters: 265,
    FailedEmail: 266,
    ContactUs: 267,
    Compressed: 268,
    Undefined: 269,
    WarningDatabase: 270,
}