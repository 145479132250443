import React from 'react';
import { Layout } from 'antd';
import HomeContent from './homeContent';
import HeaderContent from "./header";
import FooterContent from "./footer";
import { useMediaQuery } from "react-responsive";

export default function Main() {
    const { Header, Footer, Content } = Layout;

    const handleClickScroll = (section) => {
        const element = document.getElementById(section);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.error(`Element with ID ${section} not found`);
        }
    };

    const isStupidMobile = useMediaQuery({ maxWidth: 370 });

    return (
        <Layout>
            <Header id='section-top' className={isStupidMobile ? 'z-50 h-16 bg-vvoids-darker text-vvoids-white border-b-2 border-vvoids-white p-2' : 'z-50 h-16 bg-vvoids-darker text-vvoids-white border-b-2 border-vvoids-white'}><HeaderContent initLanguage="en" /></Header>
            <Layout id='shell_content_fit'>
                <Content className=""><HomeContent handleClickScroll={handleClickScroll} /></Content>
            </Layout>
            <Footer className='z-50 text-center text-white bg-vvoids-darker'><FooterContent /></Footer>
        </Layout>
    );
}