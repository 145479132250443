import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider, } from 'react-query';
import { ToastContainer } from 'react-toastify';
import './i18n.js';
import axios from 'axios';
import Index from './index.jsx';
import Config from './variables.json';
import "./web.config";

//STYLING
import './assets/styling/output.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styling/shell.css';

const queryClient = new QueryClient();

axios.defaults.baseURL = Config.Variables.ApiUrl;
axios.defaults.headers.common['x-functions-key'] = process.env.REACT_APP_API_KEY;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ToastContainer autoClose={6000} limit={4} />
    <QueryClientProvider client={queryClient}>
      {
        <Index />
      }
    </QueryClientProvider>
  </React.StrictMode>
);