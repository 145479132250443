import React from 'react';
import { Layout } from 'antd';
import HeaderContent from './header';
import FooterContent from './footer';

export default function PageTerms() {
    const { Header, Footer, Content } = Layout;

    return (
        <Layout>
            <Header id='section-top' className='depth z-50 h-16 text-black bg-gray-100'><HeaderContent /></Header>
            <Layout id='shell_content_fit'>
                <Content className='text-left m-5 text-black bg-gray-100'>
                    <strong>Gebruiksvoorwaarden</strong> | (VIRTUAL VOIDS)
                    <p>Momenteel ENKEL in het Nederlands</p>
                </Content>
            </Layout>
            <Footer className='depth z-50 text-center text-white bg-gray-800'><FooterContent /></Footer>
        </Layout>
    );
}