import React from 'react';
import { Layout } from 'antd';
import HeaderContent from './header';
import FooterContent from './footer';

export default function PagePrivacy() {
    const { Header, Footer, Content } = Layout;

    return (
        <Layout>
            <Header id='section-top' className='depth z-50 h-16 text-black bg-gray-100'><HeaderContent /></Header>
            <Layout id='shell_content_fit'>
                <Content className='text-left m-5 text-black bg-gray-100'>
                    <strong>Privacy beleid</strong> | (VIRTUAL VOIDS WEBSITE)
                    <p>Momenteel ENKEL in het Nederlands</p>
                    <p>Privacy beleid</p>
                    <p>VIRTUAL VOIDS Privacy beleid</p>
                    <p>In de huidige maatschappij is het essentieel dat u als betrokkene in een overeenkomst met VIRTUAL VOIDS kan rekenen op uw privacy en daarom zal VIRTUAL VOIDS ook steeds uw persoonlijke gegevens met respect behandelen. Welke gegevens BV VIRTUAL VOIDS & STREET OF MARKETING (hierna: VIRTUAL VOIDS) bewaart, verzamelt en verwerkt, vindt u hieronder terug. Bij verdere vragen over uw privacy, aarzel niet om een mail te sturen via het contact formulier op de website: https://vvoids.com.</p>
                    <p>Het privacy beleid kan gewijzigd worden als er nieuwe ontwikkelingen plaatsvinden. Het meest actuele beleid vindt u steeds terug op de website van VIRTUAL VOIDS. De datum waarop het privacy beleid is geplaatst staat hier onderaan, dan ziet u ook wanneer het gewijzigd is.</p>

                    <strong>Artikel 1: Algemene bepalingen</strong>
                    <p>1.	VIRTUAL VOIDS zet steeds in op het verzekeren van de bescherming van de natuurlijke personen in verband met de verwerking van persoonsgegevens. Ze houdt hierbij rekening met de regels betreffende het vrij verkeer van persoonsgegevens. Deze regels beschermen de grondrechten van de natuurlijke personen en hun fundamentele rechten en vrijheden, in het bijzonder bescherming van persoonsgegevens. [1]</p>
                    <p>2.	De gegevens van rechtspersonen kennen geen bescherming.</p>
                    <p>3.	VIRTUAL VOIDS werkt met gedeeltelijke geautomatiseerde verwerking van persoonsgegevens, soms zal men ook manueel persoonsgegevens in een bestand opnemen. [2],[3]</p>
                    <p>4.	VIRTUAL VOIDS bevindt zich binnen de Europese Unie.[4] Met name in de Stropkaai 14, 9000 Gent, België.</p>

                    <strong>Artikel 2: Definities[5]</strong>
                    <p>Volgende definities zijn volledig in overeenstemming met artikel 4 van de algemene verordening betreffende gegevensbescherming.</p>
                    <p>1.	Persoonsgegevens</p>
                    <p>Betreft alle informatie over een geïdentificeerde of identificeerbare natuurlijke persoon: als identificeerbaar wordt beschouwd een natuurlijke persoon die direct of indirect kan worden geïdentificeerd. Het zal dan gaan over informatie die de verwerker zal krijgen aan de hand van een identificatie zoals een naam, locatiegegevens of een online identificatie.</p>
                    <p>2.	Verwerking:</p>
                    <p>De verwerking zal gebeuren bij een bewerking of een geheel van bewerkingen met betrekking tot persoonsgegevens. Die verwerking kan volgende zaken betreffen: verzamelen, vastleggen, ordenen, structureren, opslaan, bijwerken of wijzigen, opvragen, raadplegen, gebruiken, verstrekken door middel van doorzending, verspreiden of op andere wijze ter beschikking stellen, aligneren of combineren, afschermen, wissen of vernietigen van gegevens.</p>
                    <p>3.	Pseudonimisering:</p>
                    <p>Het verwerken van persoonsgegevens op zodanige wijze dat de persoonsgegevens niet meer aan een specifieke betrokkene kunnen worden gelinkt zonder dat er bijkomende gegevens worden gebruikt.</p>
                    <p>4.	Bestand:</p>
                    <p>Elk gestructureerd geheel van persoonsgegevens die volgens bepaalde criteria toegankelijk zijn.</p>
                    <p>5.	Verwerkingsverantwoordelijke:</p>
                    <p>VIRTUAL VOIDS die het doel van en de middelen voor de verwerking van de persoonsgegevens vaststelt.</p>
                    <p>6.	Verwerker:</p>
                    <p>VIRTUAL VOIDS</p>
                    <p>7.	Ontvanger:</p>
                    <p>Een rechtspersoon of een overheidsinstantie waaraan de persoonsgegevens worden verstrekt.</p>
                    <p>8.	Toestemming van de betrokkene:</p>
                    <p>Elke vrije, specifieke, geïnformeerde en ondubbelzinnige wilsuiting waarmee de betrokkene door middel van het aanvinken van het vakje gelezen en goedgekeurd de verwerking van persoonsgegevens aanvaardt.</p>
                    <p>9.	Inbreuk in verband met persoonsgegevens:</p>
                    <p>Een inbreuk op de beveiliging die per ongeluk of op onrechtmatige wijze leidt tot de vernietiging, het verlies, de wijziging of de ongeoorloofde verstrekking van of de ongeoorloofde toegang tot doorgezonden, opgeslagen of anderszins verwerkte gegevens.</p>
                    <p>10.	Hoofdvestiging:</p>
                    <p>Gent</p>
                    <p>11.	Toezichthoudende autoriteit:</p>
                    <p>Een door een lidstaat ingevolge artikel 51 ingestelde onafhankelijke overheidsinstantie: hier de gegevensbeschermingsautoriteit.</p>

                    <strong>Artikel 3: Beginselen inzake verwerking van persoonsgegevens</strong>
                    <p>VIRTUAL VOIDS zal de persoonsgegevens:[6]</p>
                    <p>1.	Verwerken op een manier die ten aanzien van de betrokkene rechtmatig, behoorlijk en transparant is. Via dit privacy beleid laat VIRTUAL VOIDS iedere betrokkene kennismaken met de manier van verwerken en de te bereiken doelstellingen. Elke betrokkene kan ook steeds bij vragen contact opnemen met VIRTUAL VOIDS.</p>
                    <p>2.	Verzamelen, namelijk de volgende: het E-mailadres, de voornaam en achternaam, het aantal bestelde tickets per soort en de geboortedatum. Deze gegevens worden verzameld met het oog op het goed uitvoeren van de overeenkomt. Ze worden dus gebruikt om de bestelling en de betaling te bevestigen via mails. Verder worden de tickets op naam gemaakt. Ook worden deze gebruikt om latere klantenondersteuning te voorzien. Verder vraagt VIRTUAL VOIDS ook de geboortedatum om te controleren dat de betrokkene ouder is dan 13 jaar.[7],[8]</p>
                    <p>3.	en dus ook de elektronische identificatiegegevens zoals het IP-adres van de gebruiker, het type toestel van de gebruiker en informatie over via welke bron de betrokkene de website heeft bereikt (via bv. Facebook, mail, Instagram). Het IP-adres wordt bijgehouden om misbruik tegen te gaan, de andere gegevens zijn puur voor statische doeleinden bestemd. Bij het bewaren zullen voor statistische doeleinden de gegevens worden gepseudonimiseerd.</p>
                    <p>4.	Enkel vragen die noodzakelijk zijn om een goede dienst te kunnen verschaffen.</p>
                    <p>5.	Bewaren voor een termijn van twee jaar, zodat de bestelling correct kunnen worden afgewerkt en eventueel herzien door VIRTUAL VOIDS. Verder worden de gegevens bewaard met het oog op statistische doeleinden zoals het analyseren van de verkoop en het ontdekken van trends.</p>
                    <p>6.	op een integere en vertrouwelijke manier behandelen.</p>

                    <strong>Artikel 4: Rechtmatigheid van verwerking</strong>
                    <p>1.	VIRTUAL VOIDS zal steeds aan de betrokkene toestemming vragen voor de verwerking van de persoonsgegevens.</p>
                    <p>2.	De verwerking is noodzakelijk voor de uitvoering van de overeenkomst.</p>

                    <strong>Artikel 5: Voorwaarden voor toestemming</strong>
                    <p>1.	VIRTUAL VOIDS zal een bestelling pas kunnen aanvaarden als de betrokkene zich expliciet akkoord heeft verklaard met het privacy beleid.</p>
                    <p>2.	Wanneer de betrokkene zijn toestemming wenst in te trekken dan dient deze daarvoor een mail te sturen naar VIRTUAL VOIDS. Het is echter wel zo dat de intrekking van de toestemming de reeds eerder gebeurde verwerking onverlet laat.</p>
                    <p>3.	Omtrent de toestemming van kinderen: VIRTUAL VOIDS zal de toestemming aanvaarden als de betrokkene ouder is dan 13 jaar. De ouders zullen een mail krijgen om de bestelling van het kind te plaatsen als ze akkoord zijn met de bestelling. Wanneer dit niet het geval is, zal er geen bestelling kunnen plaatsvinden zonder toestemming van de ouders.</p>

                    <strong>Artikel 6: verstrekken van informatie aan de ontvanger</strong>
                    <p>1.	VIRTUAL VOIDS zal de informatie die de betrokkene verschaft doorzenden naar de ontvanger, hier zal dat steeds de organisator zijn van het event en de payment provider Mollie.[9] VIRTUAL VOIDS zal de betrokkene steeds op de hoogte stellen wie de organisator is en de betrokkene kan op de website van Mollie zowel de algemene voorwaarden als het privacybeleid terugvinden. (https://www.mollie.com/be/privacy ).</p>
                    <p>2.	De ontvanger zal beschikken over de gegevens die VIRTUAL VOIDS verkrijgt van de betrokkene.</p>
                    <p>3.	De informatie bevindt zich op een Cloud, die VIRTUAL VOIDS huurt bij Google. VIRTUAL VOIDS verzendt al de gegevens met een beveiligde verbinding (SSL).</p>
                    <p>4.	Ook zullen overheidsinstellingen of andere bevoegde instanties toegang hebben tot de persoonsgegevens van de klanten waar dit wettelijk is verplicht.</p>

                    <strong>Artikel 7: de beveiliging van persoonsgegevens</strong>
                    <p>1.	VIRTUAL VOIDS neemt als verwerkingsverantwoordelijke, rekening houdend met de waarschijnlijkheid en ernst van de risico's, passende technische en organisatorische maatregelen om de persoonsgegevens zo goed als mogelijk veilig te houden. Die maatregelen omvatten het volgende[10]:</p>
                    <p>Het opladen van de gegevens op een beveiligde Cloud, die VIRTUAL VOIDS huurt bij Google. Alle gegevens worden via een beveiligde SSL-verbinding verstuurd.</p>
                    <p>De site van VIRTUAL VOIDS wordt ook regelmatig aan test onderworpen om de veiligheid te kunnen blijven garanderen.</p>
                    <p>2.	Als er toch een inbreuk is in het verband met de persoonsgegevens van de betrokkene dan zal VIRTUAL VOIDS dat aan deze melden.</p>

                    <strong>Referenties:</strong>
                    <p>[1] Artikel 1 van de verordening betreffende de bescherming van natuurlijke personen in verband met de verwerking van persoonsgegevens en betreffende het vrije verkeer van die gegevens en tot intrekking van Richtlijn 95/46/EG (hierna: algemene verordening gegevensbescherming).</p>
                    <p>[2] Artikel 2 algemene verordening gegevensbescherming.</p>
                    <p>[3] Artikel 2 wet betreffende de bescherming van natuurlijke personen met betrekking tot de verwerking van persoonsgegevens (hierna: Kaderwet).</p>
                    <p>[4] Artikel 3 algemene verordening gegevensbescherming.</p>
                    <p>[5] Artikel 4 algemene verordening gegevensbescherming.</p>
                    <p>[6] Artikel 5 algemene verordening gegevensbescherming.</p>
                    <p>[7] Artikel 8, tweede lid algemene verordening gegevensbescherming.</p>
                    <p>[8] Artikel 7 Wet betreffende de bescherming van natuurlijke personen met betrekking tot de verwerking van persoonsgegevens.</p>
                    <p>[9] Artikel 15, eerste lid algemene verordening gegevensbescherming.</p>
                    <p>[10] Artikel 32 algemene verordening gegevensbescherming.</p>
                    <p>[11] Artikel 12, eerste lid algemene verordening gegevensbescherming.</p>
                    <p>[12] Artikel 12, tweede lid algemene verordening gegevensbescherming.</p>
                    <p>[13] Artikel 12; derde lid algemene verordening gegevensbescherming.</p>
                    <p>[14] Artikel 14 algemene verordening gegevensbescherming.</p>
                    <p>[15] Artikel 15 algemene verordening gegevensbescherming.</p>
                    <p>[16] Artikel 16 algemene verordening gegevensbescherming.</p>
                    <p>[17] Artikel 17 algemene verordening gegevensbescherming.</p>
                    <p>[18] Artikel 18 algemene verordening gegevensbescherming.</p>
                    <p>[19] Artikel 20 algemene verordening gegevensbescherming.</p>
                    <p>[20] Artikel 21 algemene verordening gegevensbescherming.</p>
                    <p>[21] Artikel 77 algemene verordening gegevensbescherming.</p>
                </Content>
            </Layout>
            <Footer className='depth z-50 text-center text-white bg-gray-800'><FooterContent /></Footer>
        </Layout>
    );
}