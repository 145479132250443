import { useMutation, useQuery } from "react-query";
import { ENABLE_LOG, QueryCase } from "./enums";
import axios from "axios";

const AxiosGet = async (route) => {
    return await axios.get(route).then((raw) => {
        AxiosLog(raw);
        return raw.data;
    }).catch((raw) => {
        AxiosLog(raw);
        return null;
    })
}

const AxiosPost = async (route, body) => {
    return await axios.post(route, body).then((raw) => {
        AxiosLog(raw);
        return raw.data;
    }).catch((raw) => {
        AxiosLog(raw);
        return null;
    })
}

export function AxiosLog(raw) {
    if (ENABLE_LOG.AXIOS) {
        console.log({
            url: `[${raw.config?.method}] ${raw.config?.baseURL} ${raw.config?.url}`,
            status: raw.status,
            data: {
                condition: raw.data?.condition || raw.response?.data?.condition,
                data: raw.data?.data || raw.response?.data?.data,
                details: raw.data?.details || raw.response?.data?.details,
                isDataCompressed: raw.data?.isDataCompressed || raw.response?.data?.isDataCompressed,
                reflection: raw.data?.reflection || raw.response?.data?.reflection
            }
        });
    }
}

export function GetData(queryId, body = null, enabled = true, retry = 1) {
    const response = useQuery({
        queryKey: queryId,
        queryFn: () => { return body ? AxiosPost(queryId, body) : AxiosGet(queryId) },
        retry: retry,
        refetchOnWindowFocus: false,
        enabled: enabled
    });

    return {
        isLoading: response.isLoading || response.isFetching || response.isRefetching,
        data: response.data?.data ?? null,
        condition: response.data?.condition ?? QueryCase.Unknown,
        isFalse: response.isLoading || response.isFetching || response.isRefetching || response.isError || response.isLoadingError || response.isRefetchError || (response.data?.condition !== QueryCase.Success && response.data?.condition !== QueryCase.Empty),
        isEmpty: response.data?.condition === QueryCase.Empty,
        refetch: response.refetch
    };
}

export const MutateData = (queryId, onEnd = null, onCases = null) => {
    return useMutation({
        mutationKey: queryId,
        mutationFn: (variables) => { return variables ? AxiosPost(queryId, variables) : AxiosGet(queryId) },
        onSuccess: (data) => {
            if (onEnd) { onEnd(data); };
            let noCase = true;
            onCases?.forEach(element => {
                if (element.case === data?.condition) {
                    noCase = false;
                    element.onCase(data);
                }
            });
            if (noCase) {
                onCases?.find((element) => { return element.case === QueryCase.Error; })?.onCase();
            }
        },
        onError: () => {
            if (onEnd) { onEnd(); };
            onCases?.find((element) => { return element.case === QueryCase.Error; })?.onCase();
        },
        retry: 0
    })
}