import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function PageRedirect(props) {
    const navigate = useNavigate();

    useEffect(() => {
        if (props.force) {
            window.location.href = props.redirect;
        }
        else {
            navigate(props.redirect);
        }
    });


    return (<></>);
}