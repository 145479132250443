import { useTranslation } from "react-i18next";
import { SendOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Image, Input, Layout, Space, Spin, Upload } from "antd";
import { Select } from 'antd';
import Logo from '../../assets/sprites/logos/SanctaLogo.png';
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { DEFAULT, LANGUAGES, QueryCase, QueryId, ROUTES, ToastId } from '../../data/enums';
import ReactCountryFlag from "react-country-flag";
import LayoutOne from '../../assets/sprites/images/LayoutOne.png';
import LayoutTwo from '../../assets/sprites/images/LayoutTwo.png';
import LayoutThree from '../../assets/sprites/images/LayoutThree.png';
import PatternOne from '../../assets/sprites/images/PatternOne.jpg';
import PatternTwo from '../../assets/sprites/images/PatternTwo.jpg';
import ImgCrop from "antd-img-crop";
import { handleUploadPicture, notifyError, notifySuccess } from "../../data/extensions";
import { GetData, MutateData } from "../../data/axios";
import * as htmlToImage from 'html-to-image';

export default function Photobooth(props) {
    const { Header, Content } = Layout;
    const { t } = useTranslation();
    const [email, setEmail] = useState(DEFAULT.TEXT);
    const [layout, setLayout] = useState(0);
    const [design, setDesign] = useState(0);
    const [isEmailFalse, setIsEmailFalse] = useState(false);

    const actionGetBooth = GetData(QueryId.SelectPhotobooth, { email: email }, false, 0);

    const saveImage = () => {
        htmlToImage.toPng(document.getElementById("booth"), { quality: 0.95 })
            .then(function (dataUrl) {
                var link = document.createElement("a");
                link.download = "my-photobooth.png";
                link.href = dataUrl;
                link.click();
            });
    }

    return (
        <>
            <Layout className="h-screen">
                <Header className="px-4 z-50 h-16 text-md bg-vvoids-darker text-vvoids-white border-b-2 border-vvoids-white"><HeaderSancta initLanguage="nl" /></Header>
                <Layout className="bg-vvoids-dark">
                    <Content>
                        <div className="m-5 bg-vvoids-dark text-vvoids-white">
                            <p>{t("")}Hoi iedereen, juf Synn van 3B hier. Mijn broer heeft een kleine effort gedaan om leuke foto collages tijdens onze wandeltocht te kunnen gebruiken.</p>
                            <br />
                            <p>{t("")}Om je voortgang op te slaan hangen we de foto's vast aan een emailadres van de ouders.</p>
                            <br />
                            {actionGetBooth.isLoading || !actionGetBooth.isFalse ? null :
                                <Space direction="horizontal">
                                    <Input status={isEmailFalse ? "error" : ""} placeholder={t("placeholder_email")} required allowClear onChange={(event) => { setIsEmailFalse(false); setEmail(event.target.value); }} />
                                    <Button className="bg-vvoids-blue border-vvoids-blue py-0" onClick={() => { if (email.includes('@') && email.includes('.')) { setIsEmailFalse(false); actionGetBooth.refetch(); } else { setIsEmailFalse(true); } }}><SendOutlined /></Button>
                                </Space>
                            }
                        </div>
                        {actionGetBooth.isLoading ? <Spin size="large" className="flex justify-center"></Spin> : !actionGetBooth.isFalse ?
                            <div className="bg-vvoids-darker relative">
                                <div className="text-center">
                                    <div className="pt-5 m-5 text-xl text-vvoids-white">{t("")}Profile: {email}</div>
                                    <div className="m-5 text-xl text-vvoids-white">{t("")}View layout:</div>
                                </div>
                                <div className="flex justify-around p-2">
                                    <Button onClick={() => { setLayout(0) }} className="!w-20 !h-40 !p-0" type={layout === 0 ? "primary" : ""} icon={<img className="p-1" src={LayoutOne} alt="-" />} size={"large"} />
                                    <Button onClick={() => { setLayout(1) }} className="!w-20 !h-40 !p-0" type={layout === 1 ? "primary" : ""} icon={<img className="p-1" src={LayoutTwo} alt="-" />} size={"large"} />
                                    <Button onClick={() => { setLayout(2) }} className="!w-20 !h-40 !p-0" type={layout === 2 ? "primary" : ""} icon={<img className="p-1" src={LayoutThree} alt="-" />} size={"large"} />
                                </div>
                                <div className="text-center">
                                    <div className="m-5 text-xl text-vvoids-white">{t("")}View design:</div>
                                </div>
                                <div className="flex justify-around p-2">
                                    <Button onClick={() => { setDesign(0) }} className="!w-20 !h-40 !p-0" type={design === 0 ? "primary" : ""} icon={<img className="p-1" src={PatternOne} alt="-" />} size={"large"} />
                                    <Button onClick={() => { setDesign(1) }} className="!w-20 !h-40 !p-0" type={design === 1 ? "primary" : ""} icon={<img className="p-1" src={PatternTwo} alt="-" />} size={"large"} />
                                </div>
                                <Button className="bg-vvoids-blue border-vvoids-blue py-0" onClick={() => { saveImage(); }}><SendOutlined /></Button>

                                <div className="flex justify-center my-7">
                                    <div className={`relative bg-vvoids-grey rounded-3xl layout ${design === 0 ? "boothPatternOne" : "boothPatternTwo"}`}>
                                        <div className={`layout-${layout}`}>
                                            <Picture maxSize={99} maxWidth={375} maxHeight={375} aspect={1} classNameOverride={"ant-upload-photobooth-1"} boothId={actionGetBooth.data.sanctaBoothId} pictureIndex={1} picture={actionGetBooth.data.photoUrl1} />
                                            <Picture maxSize={99} maxWidth={375} maxHeight={667} aspect={0.5622} classNameOverride={"ant-upload-photobooth-2"} boothId={actionGetBooth.data.sanctaBoothId} pictureIndex={2} picture={actionGetBooth.data.photoUrl2} />
                                            <Picture maxSize={99} maxWidth={375} maxHeight={667} aspect={0.5622} classNameOverride={"ant-upload-photobooth-3"} boothId={actionGetBooth.data.sanctaBoothId} pictureIndex={3} picture={actionGetBooth.data.photoUrl3} />
                                            <Picture maxSize={99} maxWidth={375} maxHeight={375} aspect={1} classNameOverride={"ant-upload-photobooth-4"} boothId={actionGetBooth.data.sanctaBoothId} pictureIndex={4} picture={actionGetBooth.data.photoUrl4} />
                                            <Picture maxSize={99} maxWidth={800} maxHeight={350} aspect={2.2857} classNameOverride={"ant-upload-photobooth-5"} boothId={actionGetBooth.data.sanctaBoothId} pictureIndex={5} picture={actionGetBooth.data.photoUrl5} />
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute top-0 -z-50">
                                    <div id="booth" className={`relative bg-vvoids-grey rounded-3xl layout ${design === 0 ? "boothPatternOne" : "boothPatternTwo"}`}>
                                        <div className={`layout-${layout}`}>
                                            <Picture maxSize={99} maxWidth={375} maxHeight={375} aspect={1} classNameOverride={"ant-upload-hidden ant-upload-photobooth-1"} boothId={actionGetBooth.data.sanctaBoothId} pictureIndex={1} picture={actionGetBooth.data.photoUrl1} />
                                            <Picture maxSize={99} maxWidth={375} maxHeight={667} aspect={0.5622} classNameOverride={"ant-upload-hidden ant-upload-photobooth-2"} boothId={actionGetBooth.data.sanctaBoothId} pictureIndex={2} picture={actionGetBooth.data.photoUrl2} />
                                            <Picture maxSize={99} maxWidth={375} maxHeight={667} aspect={0.5622} classNameOverride={"ant-upload-hidden ant-upload-photobooth-3"} boothId={actionGetBooth.data.sanctaBoothId} pictureIndex={3} picture={actionGetBooth.data.photoUrl3} />
                                            <Picture maxSize={99} maxWidth={375} maxHeight={375} aspect={1} classNameOverride={"ant-upload-hidden ant-upload-photobooth-4"} boothId={actionGetBooth.data.sanctaBoothId} pictureIndex={4} picture={actionGetBooth.data.photoUrl4} />
                                            <Picture maxSize={99} maxWidth={800} maxHeight={350} aspect={2.2857} classNameOverride={"ant-upload-hidden ant-upload-photobooth-5"} boothId={actionGetBooth.data.sanctaBoothId} pictureIndex={5} picture={actionGetBooth.data.photoUrl5} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                    </Content>
                </Layout>
            </Layout>
        </>
    );
}

function Picture(props) {
    const { t } = useTranslation();

    const [picture, setPicture] = useState(props.picture);

    const updatePicture = MutateData(QueryId.UpdatePhotoboothPhoto,
        () => { }, [
        { case: QueryCase.Success, onCase: () => { notifySuccess(ToastId.ActionSuccess); } },
        { case: QueryCase.Error, onCase: () => { setPicture(props.picture); notifyError(ToastId.ActionError) } }
    ]);

    const onFinish = (file) => {
        let properties = {};
        properties.url = DEFAULT.STRINGEMPTY;

        if (file) {
            handleUploadPicture(file, props.maxSize, props.maxWidth, props.maxHeight, "sanctamaria/photobooth/pictures").then((result) => {
                properties.boothId = props.boothId
                properties.url = result;
                properties.urlIndex = props.pictureIndex;
                setPicture(result);
                updatePicture.mutate(properties);
            }).catch(() => {
                setPicture(picture);
                notifyError(ToastId.ActionError);
            });
        }
        else {
            notifyError(ToastId.ActionError);
        }
    };

    return (<div className="flex">
        <ImgCrop quality={1} fillColor="transparant" modalTitle={<><p className="mb-2">{t("edit_picture")}</p></>} resetText={t("reset")} modalOk={t("confirm")} modalCancel={t("cancel")} zoomSlider aspect={props.aspect} showReset showGrid>
            <Upload className={props.classNameOverride} showUploadList={{ showPreviewIcon: false }} fileList={[]} maxCount={1} accept="image/jpeg,image/png" beforeUpload={(file) => { onFinish(file); return false; }} listType="picture-card">
                {picture ? <Image preview={false} className="rounded-lg" src={picture} /> : <PlusOutlined />}
            </Upload>
        </ImgCrop>
    </div>);
}

function HeaderSancta(props) {
    const { t, i18n } = useTranslation();
    const [languageValue, setLanguageValue] = useState(t(`language_${props.initLanguage}`));
    const [menuLanguagesOpen, setMenuLanguagesOpen] = useState(false);
    const handleChange = (value) => {
        i18n.changeLanguage(value);
        setLanguageValue(value);
    };

    useEffect(() => {
        i18n.changeLanguage(props.initLanguage ?? i18n.language);
        setLanguageValue(props.initLanguage ?? i18n.language);
    }, [i18n, props.initLanguage]);

    return (
        <div className='flex justify-between align-center h-full'>
            <Link to={ROUTES.SANCTA} className="invert_hover">
                <div className='flex h-full'>
                    <img className='h-4/5 m-auto max-h-[70px]' src={Logo} alt={t("sancta_maria")} />
                    <p className="font-bold my-auto pl-2">{t("sancta_maria").toUpperCase()}</p>
                </div>
            </Link>
            <div className="flex gap-4 my-auto">
                <Select
                    defaultValue={LANGUAGES.NL}
                    onChange={handleChange}
                    onBlur={() => { setMenuLanguagesOpen(false); }}
                    options={[
                        {
                            value: LANGUAGES.EN, label: <>
                                <ReactCountryFlag style={{ width: "32px" }} countryCode="GB" svg />
                            </>
                        },
                        {
                            value: LANGUAGES.NL, label: <>
                                <ReactCountryFlag style={{ width: "32px" }} countryCode="NL" svg />
                            </>
                        },
                    ]}
                    value={languageValue}
                    open={menuLanguagesOpen}
                    onClick={() => setMenuLanguagesOpen(!menuLanguagesOpen)}
                />
            </div>
        </div>
    )
}