import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import localEN from "./assets/localization/en.json";
import localNL from "./assets/localization/nl.json";
import { LANGUAGES } from "./data/enums";

const resources = {
    en: { translation: localEN },
    nl: { translation: localNL },
};

i18n.use(initReactI18next).init({
    resources,
    lng: LANGUAGES.EN,
    fallbackLng: [LANGUAGES.NL]
});

export default i18n;