import { Button, Select } from 'antd';
import Logo from '../../assets/sprites/logos/logo256.png';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { LANGUAGES, ROUTES } from '../../data/enums';
import ReactCountryFlag from "react-country-flag";
import { useMediaQuery } from 'react-responsive'

export default function HeaderContent(props) {
    const { t, i18n } = useTranslation();
    const [languageValue, setLanguageValue] = useState(t("language_en"));
    const [menuLanguagesOpen, setMenuLanguagesOpen] = useState(false);
    const handleChange = (value) => {
        i18n.changeLanguage(value);
        setLanguageValue(value);
    };

    useEffect(() => {
        i18n.changeLanguage(props.initLanguage ?? i18n.language);
        setLanguageValue(props.initLanguage ?? i18n.language);
    }, [i18n, props.initLanguage]);

    const handleClickScroll = (section) => {
        const element = document.getElementById(section);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.error(`Element with ID ${section} not found`);
        }
    };

    const isMobile = useMediaQuery({ maxWidth: 600 });

    return (
        <div className='flex justify-between align-center h-full'>
            <Link to={ROUTES.HOME} className="invert_hover">
                <div className='flex h-full'>
                    <img className='h-4/5 m-auto max-h-[70px]' src={Logo} alt={t("virtual_voids")} />
                    <p className="font-bold">{t("virtual_voids").toUpperCase()}</p>
                </div>
            </Link>
            <div className="flex gap-4 my-auto">
                <Select
                    defaultValue={LANGUAGES.EN}
                    onChange={handleChange}
                    onBlur={() => { setMenuLanguagesOpen(false); }}
                    options={[
                        {
                            value: LANGUAGES.EN, label: <>
                                <ReactCountryFlag style={{ width: "32px" }} countryCode="GB" svg />
                                {isMobile ? '' : t("language_en")}

                            </>
                        },
                        {
                            value: LANGUAGES.NL, label: <>
                                <ReactCountryFlag style={{ width: "32px" }} countryCode="NL" svg />
                                {isMobile ? '' : t("language_nl")}
                            </>
                        },
                    ]}
                    value={languageValue}
                    open={menuLanguagesOpen}
                    onClick={() => setMenuLanguagesOpen(!menuLanguagesOpen)}
                />
                {isMobile ? null : <Button type="primary" className="text-vvoids-white bg-vvoids-purple" onClick={() => handleClickScroll("contact")}>{t("contact")}</Button>}
            </div>
        </div>
    )
}