import * as jose from "jose";
import { Tag } from "antd";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { v4 as uuid } from "uuid";
import { BlobServiceClient } from "@azure/storage-blob";
import { t } from "i18next";
import { DEFAULT } from "./enums";

export function ToLowerAlphanumeric(value) {
    return value?.toLowerCase()?.match(/[a-z0-9]/g)?.join(DEFAULT.STRINGEMPTY);
}

export function TransformRangePickerResult(values) {
    const formattedData = values.map(item => dayjs(item["$d"]).toDate()).sort(SortOnDigits).map(date => dayjs(date).format("YYYY-MM-DD"));

    return { form_field_activeFrom: formattedData[0], form_field_activeUntil: formattedData[1] };
}

export function TransformDatePickerResult(value) {
    return dayjs(dayjs(value["$d"]).toDate()).format("YYYY-MM-DD");
}

export function TransformTimePickerRangeResult(values) {
    const formattedData = values.map(item => dayjs(item["$d"]).toDate()).map(date => dayjs(date).format("YYYY-MM-DD HH:mm"));

    return { form_field_activeFrom: formattedData[0], form_field_activeUntil: formattedData[1] };
}

export function JsonToTableBody(data) {
    const keys = Object.keys(data);
    return keys.map((key, index) => {
        return (
            <tbody key={uuid()}>
                <tr>
                    <td className="bg-sonar-grey text-sonar-white p-2 rounded-l-lg">{t(key + "_sonar")}</td>
                    <td className="bg-gray-100 p-2 rounded-r-lg">
                        {data[key] === undefined || data[key] === null || typeof data[key] !== "object" ? data[key]?.startsWith("bold_") ? <b>{data[key].slice(5)}</b> : data[key] :
                            <table>
                                {JsonToTableBody(data[key])}
                            </table>
                        }</td>
                </tr>
                {index === keys.length - 1 ? null : <tr className="h-1"></tr>}
            </tbody>
        );
    });
}

export const notifyInfo = (toastId) => {
    toast.info(t(toastId), {
        toastId: toastId
    });
}
export const notifySuccess = (toastId, onClick) => {
    toast.success(t(toastId), {
        toastId: toastId,
        onClick: onClick
    });
}
export const notifyWarning = (toastId) => {
    toast.warn(t(toastId), {
        toastId: toastId
    });
}
export const notifyError = (toastId) => {
    toast.error(t(toastId), {
        toastId: toastId
    });
}

export const handleUploadPicture = async (file, maxSize, maxWidth, maxHeight, filePath) => {
    let finalUrl = null;

    try {
        //Limit image type
        if (!(file.type === "image/jpeg" || file.type === "image/png")) {
            notifyError("error_image_filetype_picture");
            return null;
        }

        //Limit upload size
        if (!((file.size / 1024 / 1024) < maxSize)) {
            notifyWarning(`${t("error_image_filesize")} ${maxSize} ${t("megabyte")}`);
            return null;
        }

        //Setup promise to draw image
        const img = new Image();
        img.src = URL.createObjectURL(file);

        await new Promise((resolve) => {
            img.onload = resolve;
        });

        let newWidth = img.width;
        let newHeight = img.height;

        //Hold on to aspect ratio but limit resolution
        if (newWidth > maxWidth) {
            newHeight *= maxWidth / newWidth;
            newWidth = maxWidth;
        }
        if (newHeight > maxHeight) {
            newWidth *= maxHeight / newHeight;
            newHeight = maxHeight;
        }

        //Setup fake image to retrieve new resolution image
        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        //Upload to Azure Blob directly using SAS
        const blobServiceClient = new BlobServiceClient("https://vvoids.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-01-01T22:15:25Z&st=2024-04-14T13:15:25Z&spr=https&sig=5xLR2FAsCnMmy3uHXcas3xbXKRbO5z1zIXb8USICJUE%3D");
        const byteArray = Uint8Array.from(Array.from(atob(canvas.toDataURL(file.type).split(",")[1]), char => char.charCodeAt(0)));

        let result = await blobServiceClient
            .getContainerClient("assets")
            .getBlockBlobClient(`${filePath}/${uuid()}.${file.type === "image/jpeg" ? "jpg" : "png"}`)
            .uploadData(byteArray.buffer, { blobHTTPHeaders: { blobContentType: file.type } });

        finalUrl = result?._response?.request?.url?.split("?")[0];

        if (!finalUrl) {
            throw new Error("Failed upload Azure");
        }
    } catch (error) {
        console.log(error);
    }

    return finalUrl;
};

export const handleClickScroll = (section) => {
    const element = document.getElementById(section);
    if (element) {
        element.scrollIntoView({ behavior: "smooth" });
    }
    window.location.href = "#" + section;
}

export const formTagsNone = (label, { required }) => (
    <>
        <span>{label}</span>
    </>
);
export const formTagsBoth = (label, { required }) => (
    <>
        {required
            ? <Tag color="error">{t("tag_required")}</Tag>
            : <Tag color="warning">{t("tag_optional")}</Tag>
        }
        <span>{label}</span>
    </>
);
export const formTagsOptionals = (label, { required }) => (
    <>
        {required
            ? <></>
            : <Tag color="warning">{t("tag_optional")}</Tag>
        }
        <span>{label}</span>
    </>
);
export const formTagsRequireds = (label, { required }) => (
    <>
        {required
            ? <Tag color="error">{t("tag_required")}</Tag>
            : <></>
        }
        <span>{label}</span>
    </>
);

export async function JwtKey() {
    return await new jose.SignJWT({ vvoids: true }).setProtectedHeader({ alg: "HS256" }).setExpirationTime("24h").sign(new TextEncoder().encode(process.env.REACT_APP_API_KEY));
}

export function SortOnDigits(a, b) { return a - b; }

export function SortOnText(a, b) { return b.localeCompare(a) }